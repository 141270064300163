<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          General
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1"
          ></span
        >
      </div>
      
    </div>
    <form class="form">
      <div class="card-body">
	  <div id="app" v-if="save_button">
        Name
		<input ref="name" class="form-control form-control-solid" type="text" v-model="generalData.name"><br/>
		Type
		
		<el-select ref="type" style="width:100%" v-model="generalData.type" :placeholder="generalData.type">
			<el-option
			  v-for="item in options_type"
			  :key="item.value"
			  :label="item.text"
			  :value="item.value">
			</el-option>
		  </el-select>
		<br/>
		<br/>
		API Endpoint
		<input ref="endpoint" class="form-control form-control-solid" type="text" v-model="generalData.endpoint"><br/>
		App route
		<input ref="route" class="form-control form-control-solid" type="text" v-model="generalData.route"><br/>
		Component title
		<input ref="title" class="form-control form-control-solid" type="text" v-model="generalData.title"><br/>
		Status
		<el-select ref="status" style="width:100%" v-model="generalData.status" :placeholder="generalData.status">
			<el-option
			  v-for="item in options_status"
			  :key="item.value"
			  :label="item.text"
			  :value="item.value">
			</el-option>
		  </el-select>
		<br/>
		<br/>
					Toggle
		<el-select ref="toggle" style="width:100%" v-model="generalData.toggle" :placeholder="generalData.toggle">
			<el-option
			  v-for="item in toggle_options"
			  :key="item.value"
			  :label="item.text"
			  :value="item.value">
			</el-option>
		  </el-select>

      </div>

      </div>
	  <div class="card-footer d-flex justify-content-end py-6 px-9">
	  <span :class="spinner"></span>
      <button v-if="save_button"
        type="button"
        class="btn btn-primary"
        @click="saveTrigger()"
        ref="kt_save_changes"
      >
      Save Changes
      </button>
    </div>
    </form>
  </div>
</template>

<script>
	import axios from 'axios'
	
    export default {
       data(){
           return {
			  generalData: {
				name:null,
				type:null,
				endpoint:null,
				route:null,
				title:null,
				status:null,
				toggle:null,
			  },
				 options_type: [
				  { value: null, text: '' },
				  { value: 'index', text: 'Index' },
				  { value: 'single', text: 'Single' }
				],
				options_status: [
				  { value: 2, text: 'Niet actief' },
				  { value: 1, text: 'Actief' },
				],
				toggle_options: [
				  { value: 'open', text: 'Open' },
				  { value: 'closed', text: 'Closed' },
				],
              spinner: '',
              save_button: true,
              uid: this.$route.params.uid
           }
       },
		methods:{
			async saveChanges () {
			  this.spinner = 'spinner spinner-sm spinner-success spinner-right'
			  this.save_button = false
			  axios({
				method: 'put',
				url: 'https://keetels-api.eliteswitch.com/api/component/'+this.uid,
				data: {
					name: this.$refs.name.value,
					type: this.generalData.type,
					endpoint: this.$refs.endpoint.value,
					route: this.$refs.route.value,
					title: this.$refs.title.value,
					status: this.generalData.status,
					toggle: this.generalData.toggle
				  }
			  }).then((response) => {
				this.spinner = ''
				this.save_button = true
			  }, (error) => {
				console.log(error)
			  })
			},
			async refreshTable () {
			  this.spinner = 'spinner spinner-sm spinner-success spinner-right'
			  this.save_button = false
			  axios({
				method: 'get',
				url: 'https://keetels-api.eliteswitch.com/api/component/'+this.uid
			  }).then((response) => {
				this.generalData.name = response.data.result.item.name
				this.generalData.type = response.data.result.item.type
				this.generalData.endpoint = response.data.result.item.endpoint
				this.generalData.route = response.data.result.item.route
				this.generalData.title = response.data.result.item.title
				this.generalData.status = response.data.result.item.status
				this.generalData.toggle = response.data.result.item.toggle
				this.spinner = ''
				this.save_button = true
			  }, (error) => {
				console.log(error)
			  })
			},
			saveTrigger() {
			  this.saveChanges()
			},

		},
		watch: {
			ajaxData: function (val) {
				this.spinner = 'spinner spinner-sm spinner-success spinner-right'
				this.save_button = false
				axios({
					method: 'options',
					url: 'https://keetels-api.eliteswitch.com/api/'+val.item.endpoint
				  }).then((response) => {
					if(response.data.result){
					 for (const field in response.data.result.item.fields) {
						this.options_col_field.push({value: field, text: field})
					 }
					}
					
					 this.spinner = ''
					 // if(response.data.result.length > 0){
						this.save_button = true
					 // }
					 

				  }, (error) => {
					console.log(error)
				  })
			
			},
		  },
		created: function(){
			this.repeaterData = [];
		},
		mounted () {
			this.refreshTable()
		  },
    }
	
</script>
